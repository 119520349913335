<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <back style="padding: 0; margin-right: auto" />
        <el-select v-model="isCreate" placeholder="是否生成合同" @change="onSearch" clearable>
          <el-option v-for="item in departmentList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="supplierCode" placeholder="请搜索供应商编号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <!-- <el-button type="primary" plain @click="handleGenerate">一键生成</el-button> -->
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :row-key="row => row.supplierId" @selection-change="handleSelectionChange" ref="multipleTable">
          <el-table-column type="selection" width="55" :reserve-selection="true" :selectable="row => row.isCreate == 0"></el-table-column>
          <el-table-column label="序号" :show-overflow-tooltip="true" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierCode" label="供应商编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contacts" label="联系人名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="telphone" label="联系电话"></el-table-column>
          <el-table-column prop="productSum" label="中标商品数"></el-table-column>
          <el-table-column prop="depositAmount" label="履约保证金（元）"></el-table-column>
          <el-table-column prop="isCreate" label="是否生成合同">
            <template slot-scope="scope">
              <span>{{ scope.row.isCreate == 0 ? "否" : "是" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <div style="text-align: center; margin: 30px 0px 30px 0">
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  //   VueEditor
  // },
  data() {
    return {
      loading: false,
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 20, // 总记录数（需要从后端获取）
      isCreate: "", //合同状态
      disabled: true,
      supplierName: "",
      supplierCode: "",
      tendersId: "",
      departmentList: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],

      multipleSelection: [], //选中数据
    }
  },

  created() {
    this.tendersId = this.$route.query.tendersId
    this.getportalPageList()
    // this.list()
  },
  methods: {
    // generateMockData(count = 20) {
    //   let tempArr = []
    //   for (let index = 0; index < count; index++) {
    //     let isCreate = 0
    //     if ([5, 6].includes(index + 1)) {
    //       isCreate = 1
    //     }

    //     tempArr.push({
    //       "supplierId": index + 1,
    //       "tendersId": 168,
    //       "supplierCode": "",
    //       "supplierName": "广州执番正健康科技有限公司",
    //       "contacts": "陈先生",
    //       "productSum": 10,
    //       "depositAmount": 0,
    //       isCreate
    //     })
    //   }
    //   return tempArr
    // },

    // paginateData(page, itemsPerPage, allData) {
    //   const startIndex = (page - 1) * itemsPerPage;
    //   const endIndex = startIndex + itemsPerPage;

    //   return allData.slice(startIndex, endIndex);
    // },

    // list() {
    //   const mockData = this.generateMockData(20);
    //   this.tableData = this.paginateData(this.currentPage, this.pageSize, mockData);
    //   this.$nextTick(() => {
    //     this.tableData.forEach(item => {
    //       if (item.isCreate == 1) {
    //         this.$refs.multipleTable.toggleRowSelection(item, true);
    //       }
    //     });
    //   })
    // },

    // 选中数据
    handleSelectionChange(array) {
      this.multipleSelection = array.filter(t => t.isCreate == 0)
    },
    // 确定
    submit() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请未生成合同的选择供应商")
        return
      }
      // tableData 和 multipleSelection 的 supplierId 一样的有 isCreate = 1 的过滤掉 省略 return
      // this.multipleSelection = this.multipleSelection.filter(t => {
      //   return !this.tableData.some(item => item.supplierId == t.supplierId && item.isCreate == 1)
      // })
      let ids = this.multipleSelection.map(e => e.supplierId)
      let params = {
        tendersId: Number(this.$route.query.tendersId),
        supplierIdList: ids,
      }
      this.loading = true
      this.$axios.post(this.$api.createTendersContract, params).then(res => {
        this.loading = false
        if (res.data.code == 100) {
          this.$message.success("新建成功！")
          this.$router.go(-1)
        } else {
          this.$message.error(res.data.desc)
        }
      })
    },
    // 查询标书供应商
    getportalPageList() {
      // this.list()
      this.loading = true
      this.$axios
        .get(this.$api.getsupplierTenders, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            isCreate: this.isCreate,
            supplierName: this.supplierName,
            supplierCode: this.supplierCode,
            tendersId: this.tendersId,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (item.isCreate == 1) {
                this.$refs.multipleTable.toggleRowSelection(item, true)
              }
            })
          })
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 搜索
    onSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getportalPageList()
    },
    // 一键生成
    handleGenerate() {
      console.log(this.multipleSelection)
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.tableData = []
      this.getportalPageList()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getportalPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .el-select,
    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  // .el-input-number {
  //   width: 255px;
  // }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #c27c88;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
